//
// user.scss
// Use this to write your custom SCSS
//

.colcentered {
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  text-align: center;
}

.rowcentered {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.primary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: 'bg-primary';
}

.paging-container {
  width: fit-content;
}

.loading-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0); /* Adjust the transparency as needed */
}

.loading-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}
