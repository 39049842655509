//
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;
@import '~react-image-gallery/styles/scss/image-gallery.scss';
a {
  color: inherit !important;
}
.authlyout {
  height: 100%;
  position: relative;
}
.authlyout .element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#product-card:hover {
  // transform: scale(1.05);
  box-shadow: (0px 1px 3px rgb(3 0 71 / 9%)) !important;
}

.category-item {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  padding-right: 2px;
}

.slider-product-item {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
}

.login-seperator {
  text-align: center;
}

.line {
  height: 1px;
  background: #000000;
}

.or-seperator {
  position: relative;
  top: -16px;
  background: #fff;
  display: inline-block;
  padding: 0 20px;
}
// .image-gallery-image {
//   background-color: #f7f7f7;
// }
.thumb {
  background-color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  height: 60px;
  width: 60px;
  padding: 0px;
}
.original {
  background-color: #ffffff;
}
.image-gallery-slides {
  border-radius: 10px;
}
.image-gallery-thumbnail {
  border-radius: 10px;
}

// .image-gallery-content {
//   width: 343px !important;
//   height: 351px !important;
// }
button#login-tab-signIn.nav-link.active {
  border: none !important;
  color: #000000;
}
button#login-tab-register.nav-link {
  border: none !important;
  color: #626262;
}
button#login-tab-signIn.nav-link {
  border: none !important;
  color: #626262;
}
button#login-tab-register.nav-link.active {
  border: none !important;
  color: #000000;
}
.nav-tabs {
  border: none !important;
}
.dash {
  border: 1px solid #000;
  width: 35px;
  height: 1px;
}
